/* src/ComingSoon.css */

.coming-soon-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.logo {
  width: 25vw;
  max-width: 100%;
  height: auto;
}

h1 {
  color: #00fff6;
  font-size: 3rem;
  margin-bottom: 20px;
}

p {
  color: white;
  font-size: 1rem;
  margin: 0;
}

.social-icons {
  margin-top: 20px;
}

.social-icons a {
  display: inline-block;
  margin-right: 10px;
  color: white;
  font-size: 2rem;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #bc00ff; /* Change color on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  h1 {
    font-size: 3rem;
  }

  .content p {
    font-size: 1.25rem;
  }

  .logo {
    width: 60vw;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }

  .content p {
    font-size: 1rem;
  }

  .logo {
    width: 50vw;
  }
}
